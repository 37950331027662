<template>
  <div>
    <el-dialog v-model="ShowForm"
               title=""
               width="50%">
      <div>
        <h3 class="formHead">{{formTitle}}</h3>
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="100px">
          <el-form-item label="Your name"
                        prop="cert_name">
            <el-input v-model="form.cert_name"></el-input>
          </el-form-item>
          <el-form-item label="Phone"
                        prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="Emssage"
                        prop="cont">
            <el-input v-model="form.cont"
                      type="textarea"></el-input>
          </el-form-item>

          <el-button type="primary"
                     @click="onSubmit('form')">Submit</el-button>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ShowForm = false">Cancel</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: 'Form-bm',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    formTitle: {
      type: String,
      default: ''
    },
    typeId: {
      type: Number,
    }
  },
  data () {
    return {
      form: {
        cert_name: '',
        phone: '',
        content: '',
      },
      rules: {
        cert_name: [
          {
            required: true,
            message: 'Please input Id name',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 64,
            message: 'Length should be 3 to 64',
            trigger: 'blur',
          },
        ],
        cont: [
          {
            required: true,
            message: 'Please input your message',
            trigger: 'blur',
          }
        ],
        phone: [
          {
            required: true,
            message: 'Please input your phone',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  setup () {
    const ShowForm = ref(false)
    const onShowVio = () => {
      ShowForm.value = true
    }
    return { ShowForm, onShowVio }
  },
  methods: {
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axiosApi('message', {
            form_type: this.typeId,
            ...this.form
          }, 'post').then(res => {
            if (res.data.status) {
              ElMessage.success('success')
              this.ShowForm = false
              this.$refs[formName].resetFields()
            } else {
              ElMessage.error('error')
            }
          })
        } else {
          return false
        }
      })
    }
  },
})
</script>

<style lang="scss" scoped>
.formHead {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}
</style>